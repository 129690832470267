import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"


// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Link, graphql } from "gatsby"
import Newslettersignup from "../components/Newslettersignup"
import { BrandColours } from "../utils/colours";

const Tags = styled.ul`
display: block;
list-style-type: disc;
margin-block-start: 1em;
margin-block-end: 1em;
margin-inline-end: 0px;
list-style: none;
padding-left: 0;
display: inline-block;


li {
padding: 0;
margin: 0;
display: inline-block;
}
.tag {
  &:nth-child(3n+1){
    background-color:${BrandColours.purple.main};
  }
  &:nth-child(3n+2){
    background-color:${BrandColours.green.main};
  }
  &:nth-child(3n+3){
    background-color:${BrandColours.blue.main};
  }

  a{
    color: white;
  }
  border-radius: 3px;
  padding: 5px 10px;
  display: inline-block;
  margin: 0 10px 10px 0;
}
a {
 text-decoration: none;
 color: #333333;
 font-size: 1.5rem;
 font-weight: 600;
}

`;


const BodyWrapper = styled.div`
  margin: 60px auto;
  max-width: 1450px;

  h1{
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 1;
    color: ${BrandColours.purple.main};
  }
`;


const TagsPage = ({
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <SEO 
      title='Tags'
      keywords={['anxious', 'extrovert', 'blog', 'breathing', 'mental', 'health', 'help', 'young', 'youngminds', 'student', 'mind', 'minds', 'exercises', 'services', 'blogs', 'calm', 'coping']}/>
      <BodyWrapper>
      <h1>Tags</h1>
      <Tags>
        {group.map(tag => (
          <li key={tag.fieldValue} className="tag">
            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className="CleanLink">
              {tag.fieldValue} ({tag.totalCount})
            </Link>
          </li>
        ))}
      </Tags>
      <Newslettersignup/>
      </BodyWrapper>
  </Layout>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`