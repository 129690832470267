import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';

function ContactForm(){
  
    return(
        <>
        <div id="ContactForm" className="compbckgrd1 black-text">
            <div className="container">
                <div className="row margin-vert-30 text-center" style={{justifyContent: "center"}}>
                    <div className="col-md-9 col-centered">
                    <div className="ml-form-embed"
                      data-account="2736478:m3i4v1y2k0"
                      data-form="3270991:y5x8u8">
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ContactForm